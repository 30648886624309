<template>
    <div class="lookup-list-table bg-white rounded-5 p-5">
        <ValidationObserver ref="form" @submit.prevent="saveTableItem">
            <form>
                <div class="modal-list-third-party-app w-100">
                    <div class="mx-2 my-2 mb-4">
                        <h1 class="page-heading" style="font-size: 1.5rem">
                            {{ isEditable ? 'Table Details' : 'Create New Lookup Table' }}
                        </h1>
                        <div class="my-2 mx-2 d-flex w-100" style="gap: 5%">
                            <Input
                                label="Name"
                                labelClass="d-flex add-new-app-label"
                                type="text"
                                class="w-30 field-container d-flex mt-2 mb-4 flex-column"
                                name="Name"
                                placeholder="Enter Lookup Table name"
                                v-model="lookupTableData.name"
                                required
                            />
                            <div class="d-flex flex-column w-70 mx-4 mt-2 mb-4" style="color: grey">
                                <div style="font-weight: 600">Ability to configure table from Member demographics</div>
                                <div class="d-flex gap-5 mt-3">
                                    <span class="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            id="enableQR"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="lookupTableData.allowAdmin"
                                            @input="lookupTableData.allowAdmin = $event.target.checked"
                                        />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="enableQR"
                                            >Admin</label
                                        >
                                    </span>
                                    <span class="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            id="enableQR"
                                            class="d-flex justify-content-end add-new-app-label right-5 color"
                                            :checked="lookupTableData.allowClinician"
                                            @input="lookupTableData.allowClinician = $event.target.checked"
                                        />
                                        <label class="margin-0 font-14 d-flex align-items-center" for="enableQR"
                                            >Clinician</label
                                        >
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Feed Item Container -->
                    <div class="modal-list-third-party-app w-100" style="margin-bottom: 50px">
                        <h1 class="px-2 page-heading">{{ isTableEditable ? 'Update Item' : 'Add New Item' }}</h1>
                        <div class="d-flex mx-2 my-2 w-100" style="gap: 10%">
                            <div class="d-flex no-wrap my-2 w-70" style="gap: 2%">
                                <Input
                                    label="Key"
                                    labelClass="d-flex add-new-app-label"
                                    type="text"
                                    class="w-50 field-container d-flex mx-2 mt-2 mb-4 flex-column"
                                    name="Link key"
                                    placeholder="Enter key"
                                    v-model="tableItem.key"
                                />

                                <Input
                                    label="Value"
                                    labelClass="d-flex add-new-app-label"
                                    type="text"
                                    class="w-50 field-container d-flex mt-2 mb-4 flex-column"
                                    name="link"
                                    rules="ValidateUrl"
                                    placeholder="Enter link"
                                    v-model="tableItem.value"
                                />
                            </div>

                            <!-- Lookup Table item bottom bar -->
                            <div class="d-flex my-4 w-20" style="min-height: 50px">
                                <div class="d-flex justify-content-end w-100">
                                    <button
                                        v-if="isTableEditable"
                                        type="button"
                                        class="no-bg text-button btn-cancel px-4"
                                        @click="resetTableItem"
                                    >
                                        Cancel
                                    </button>
                                    <button class="save-btn" type="submit">
                                        {{ isTableEditable ? 'Update' : 'Add' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Lookup Table Item List -->
                    <div class="table-container">
                        <div
                            class="d-flex justify-content-between table-header"
                            style="background: rgb(241, 247, 255); border-radius: 27px 27px 0 0"
                        >
                            <h1 class="px-5 py-3 page-heading">Item List</h1>
                        </div>
                        <div v-if="tableItemList && tableItemList.length" class="profile-listing-body px-2 pb-2">
                            <b-table id="my-table" :items="tableItemList.slice()" :fields="fields" bordered responsive>
                                <template #cell(s_no)="row">
                                    {{ row.index + 1 }}
                                </template>
                                <template #cell(value)="row">
                                    <b-tooltip
                                        :target="`${row.index} value`"
                                        :title="row.item.value"
                                        triggers="hover"
                                    />
                                    <span :id="`${row.index} value`">{{ row.item.value }}</span>
                                </template>
                                <template #cell(created_at)="row">
                                    {{ formattedDate(row.item.created_at) }}
                                </template>
                                <template #cell(modified_at)="row">
                                    {{ formattedDate(row.item.updated_at) }}
                                </template>
                                <template #cell(action)="row">
                                    <div class="d-flex justify-content-center align-items-center" style="gap: 20px">
                                        <ejs-tooltip
                                            class="tooltipcontainer"
                                            content="Edit"
                                            target="#target"
                                            cssClass="customtooltip"
                                        >
                                            <img
                                                src="@/assets/px/edit-icon.png"
                                                alt=""
                                                style="height: 20px"
                                                id="target"
                                                @click="editFeedItem(row.item)"
                                            />
                                        </ejs-tooltip>
                                        <ejs-tooltip
                                            class="tooltipcontainer"
                                            content="Delete"
                                            target="#target"
                                            cssClass="customtooltip"
                                        >
                                            <img
                                                src="@/assets/px/delete-icon.png"
                                                alt=""
                                                style="height: 20px"
                                                id="target"
                                                @click="showDeleteAlertPopUp(row.item)"
                                            />
                                        </ejs-tooltip>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div
                            v-else
                            class="fs-4 text-bolder d-flex align-items-center justify-content-center"
                            style="min-height: 200px"
                        >
                            No items created
                        </div>
                    </div>
                </div>

                <!-- Main Bottom Bar -->
                <div class="mx-2">
                    <div class="py-5 d-flex justify-content-end">
                        <button
                            v-if="isEditable"
                            class="no-bg text-button btn-cancel px-4"
                            @click="cancelEdit"
                            type="button"
                        >
                            Cancel
                        </button>
                        <button class="save-btn" @click="saveRecord" type="button">Save</button>
                    </div>
                </div>
            </form>
        </ValidationObserver>

        <div class="profile-delete-modal">
            <DeleteLookupTable
                v-if="showDeleteAlert"
                :open="showDeleteAlert"
                @cancel="showDeleteAlert = false"
                @handleDelete="handleDelete"
            >
            </DeleteLookupTable>
        </div>

        <Loading v-if="loading" />
    </div>
</template>
<script>
    import DeleteLookupTable from './delete-lookup-table';
    import Loading from '@/components/general/loading/loading.vue';
    import { dashboardBuilder, lookupTable } from '../../util/apiRequests';
    import { ValidationObserver } from 'vee-validate';
    import dayjs from '@/util/dayjs';

    const initialTableItem = {
        key: '',
        value: '',
        created_at: '',
        updated_at: '',
    };

    export default {
        name: 'LookupTableBuilder',
        components: {
            Loading,
            DeleteLookupTable,
            ValidationObserver,
        },
        data() {
            return {
                lookupTableData: {
                    name: '',
                    allowAdmin: false,
                    allowClinician: false,
                },
                tableItem: { ...initialTableItem },
                tableItemList: [],
                loading: true,
                isEditable: false,
                showDeleteAlert: false,
                TableItemIndex: null,
                isTableEditable: false,
                fields: [
                    { key: 's_no', label: 'S NO.', thStyle: { 'padding-left': '0 !important' }, class: 'table-col' },
                    { key: 'key', label: 'Key', class: 'table-col' },
                    { key: 'value', label: 'Value', class: 'table-col' },
                    { key: 'created_at', label: 'Created Date & Time', class: 'table-col' },
                    { key: 'modified_at', label: 'Modified Date & Time', class: 'table-col' },
                    { key: 'action', label: 'Action', class: 'action-sticky', stickyColumn: true, isRowHeader: true },
                ],
            };
        },
        methods: {
            formattedDate(date) {
                return date ? dayjs(date).format('MM/DD/YYYY hh:mmA') : '';
            },
            async getTableDetails() {
                try {
                    let response = await this.$api.get(lookupTable.getLookupTableById(this.$route?.query?.id));

                    if (response.data?.info?.lookupTable?.length) {
                        this.tableItemList = response.data?.info?.lookupTable;
                        this.lookupTableData = response.data?.info;
                    }
                    this.isEditable = true;
                } catch (err) {
                    this.$toasted.error('Failed to fetch details');
                }
            },
            async saveRecord() {
                try {
                    const form = this.$refs.form;
                    const isFormValid = await form.validate();
                    if (!isFormValid) {
                        window.scrollTo(0, 0);
                        this.$toasted.error('Please fill all required fields');
                        return;
                    }
                    if (this.tableItemList?.length == 0) {
                        return this.$toasted.error('Please add atleast one Link');
                    }
                    let response;
                    let endpoint = lookupTable.checkNameExits();
                    response = await this.$api.post(endpoint,{
                        TableName : this.lookupTableData.name,
                        id : this.$route?.query?.id
                    });
                    if (!response.data.success) {
                        return this.$toasted.error(response?.data?.error); 
                    }
                    this.lookupTableData = {
                        ...this.lookupTableData,
                        lookupTable: this.tableItemList,
                    };

                    if (!this.isEditable) {
                        let endpoint = lookupTable.createLookupTable();
                        response = await this.$api.post(endpoint, this.lookupTableData);
                    } else {
                        let endpoint = lookupTable.updateLookupTable(this.$route?.query?.id);
                        response = await this.$api.put(endpoint, this.lookupTableData);
                    }

                    if (response.data.success) {
                        this.$toasted.success(`Generic Url ${this.isEditable ? 'updated' : 'added'} successfully`);

                        await this.$router.replace({ name: 'LookupTableBuilderList', replace: true });
                    } else {
                        this.$toasted.error('Failed to save record');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to save record');
                }
            },
            async saveTableItem() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                const { key, value } = this.tableItem;
                if (key == '' || value == '') {
                    return this.$toasted.error('Please fill all required fields');
                }
                if (!this.isTableEditable) {
                    this.tableItem.created_at = new Date();
                    this.tableItem.updated_at = new Date();
                    this.tableItemList.push(this.tableItem);
                } else {
                    this.tableItem.updated_at = new Date();
                    this.tableItemList.forEach((item, index) => {
                        if (item.id == this.tableItem.id) {
                            this.tableItemList[index] = this.tableItem;
                        }
                    });
                }
                this.resetTableItem();
            },
            showDeleteAlertPopUp(item) {
                this.showDeleteAlert = true;
                this.TableItemIndex = item.id;
            },
            handleDelete() {
                this.tableItemList = this.tableItemList.filter((item) => item.id != this.TableItemIndex);
                this.showDeleteAlert = false;
            },
            resetTableItem() {
                this.isTableEditable = false;
                this.TableItemIndex = null;
                this.tableItem = { ...initialTableItem };
            },
            editFeedItem(item) {
                window.scrollTo(0, 0);
                this.isTableEditable = true;
                this.tableItem = { ...item };
            },
            async cancelEdit() {
                await this.$router.replace({ name: 'LookupTableBuilderList', replace: true });
                this.isEditable = false;
            },
        },
        async created() {
            if (this.$route?.query?.id) {
                await this.getTableDetails();
            }
            this.loading = false;
        },
    };
</script>

<style>
    .lookup-list-table table tbody tr > td:first-child {
        padding-left: 2.5em !important;
    }

    .lookup-list-table .b-table-sticky-column:last-child {
        right: 0;
        min-width: 100px;
    }

    .lookup-list-table.action-sticky {
        min-width: 150px;
        box-shadow: -10px 0px 10px 1px #aaaaaa;
    }

    .lookup-list-table .table-col {
        max-width: 100px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    .lookup-list-table form {
        box-shadow: none;
    }

    .color {
        accent-color: var(--base-theme-tab-color);
    }

    /* ### Custom scroll ### */

    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }
</style>

<style scoped>
    .table-header .text-button {
        background-color: #5057c3;
        color: white;
        padding: 10px;
        border-radius: 10px;
    }

    .table-container {
        border-radius: 27px;
        border: 1px solid #ebeff4;
    }

    .page-heading {
        font-weight: 700;
        border-radius: 27px 27px 0 0;
    }

    .save-btn {
        background: #5057c3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        width: 150px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }

    .btn-cancel,
    .btn-cancel:hover {
        height: 50px;
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>
